import React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import axios from 'axios';

export class PaymentCompletedPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            approved: false
        }
    }

    componentDidMount() {
        const token = new URLSearchParams(this.props.location.search).get('token');
        const instance = axios.create();
        instance.defaults.headers['Content-Type'] = 'application/json';
        instance.defaults.headers['Cache-Control'] = 'no-cache';
        instance.defaults.withCredentials = false;
        const payload = {
            ref: token
        };
        instance.post(`/.netlify/functions/process-paypal-payment`, JSON.stringify(payload)).then((resp) => {
            if (resp.data.paid === true) {
                
                window.postMessage({ paid: true });
                setTimeout(() => {
                    window.close();
                }, 1000);
            } else {
                console.error('NOT PAID', resp.data);
            }
        }).catch((err) => {
            alert(err);
        });
        
    }

    render() {
        return (
            <Layout>
                <Header title='Kailash Payment Completed' subheading='Please wait, processing your payment.' image='/img/kailash-three-bottles.webp' />
            </Layout>
        );
    }
}

export default PaymentCompletedPage